import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// import awards from 'assets/media/award.jpg';
import { fetchArticleByType } from 'api/article';
import { DummyImage } from 'config';
import { ButtonStyle } from 'components/button';
import { deleteAllCookie } from 'helpers';

const award_breakpoint = {
  bigDesktop: {
    breakpoint: { max: 4000, min: 1501 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 1500, min: 1300 },
    items: 3
  },
  tab: {
    breakpoint: { max: 1300, min: 1000 },
    items: 2
  },
  tablet: {
    breakpoint: { max: 1000, min: 0 },
    items: 1
  }
};

export const Awards = (props) => {
  const [data, setData] = useState([]);
  const [errorPage, setErrorPage] = useState(true);
  const requestParams = [
    { name: 'awards_recognition', value: 'awards_recognition' }
  ];
  const [centerMode, setCenterMode] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      fetchArticleByType(requestParams, false).then(({ data }) => {
        setData(data);
        setErrorPage(false);
      }).catch((error) => {
        if (error[0] === 401) {
          deleteAllCookie();
        }
        setErrorPage(true);
      });
    }, 2000);
    const screen_width = window.screen.width;
    screen_width < '500' ? setCenterMode(false) : setCenterMode(true);
  }, []);
  if (errorPage) {
    <div></div>;
  }
  return (
    <>
      {data?.[0]?.id &&
        <div className="w-full bg-[#ededed]" id="awards">
          <div className="sm:container mx-auto">
            <div className="flex justify-between sm:container xs:flex-col text-black xs:gap-4 sm:flex-row xs:px-4 sm:px-4 md:px-4 lg:px-4 mx-auto z-[99999] py-[3rem]">
              <div className="xs:w-full sm:w-1/3  font-medium">
                <p className="sm:text-[1rem] md:text-xl lg:text-[1.3rem] uppercase ">
                  Awards & Recognition
                </p>
              </div>
              <div className="lg:w-[36%] xl:w-1/3 xs:text-left text-right sm:text-[1rem] md:text-[1.3rem] lg:text-xl font-light hover:underline hover:cursor-pointer">
                <p onClick={() => props.history.push({ pathname: `/category/${requestParams[0].name}`, state: { fetchValues: requestParams } })}>
                  See All <span className="uppercase">Awards & Recognition</span>
                </p>
              </div>
            </div>
          </div>
          <Carousel
            responsive={award_breakpoint}
            autoPlay={true}
            infinite={true}
            centerMode={centerMode}
            className="gap-10 flex pb-10"
            autoPlaySpeed={5000}
          >
            {data.map((value, i) => {
              const image = value?.banner_image?.url || DummyImage;
              return (
                <div key={i} className="flex flex-col xs:w-full sm:w-[95%] min-h-[400px]">
                  <div className="h-[300px] bg-no-repeat bg-center bg-cover relative" style={{ backgroundImage: `url(${image})` }}>
                  </div>
                  <div className="bg-[#FDF4F5] text-black flex-1 text-black flex flex-col xs:gap-5 sm:gap-10 items-left py-8 xs:pl-5 pr-2 sm:pl-12 md:pl-12 lg:pl-12 xl:pl-12 2xl:pl-12 gap-10">
                    <div className='xs:h-[200px] sm:h-[300px] xl:h-[300px] overflow-hidden flex flex-col gap-8'>
                      <div>
                        <h2 className="font-semibold">{value?.title}</h2>
                      </div>
                      <div>
                        <p className='text-justify text-left pr-8'>{value.short_description}</p>
                      </div>
                    </div>
                    <ButtonStyle title={'learn more'} style={''} url={`/articles/${value?.alias_name}`} />
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      }
    </>
  );
};
