import React from 'react';

export const SubHeader = ({ title }) => {
    const ScrollToPage = (id) => {
        const value = document.querySelector(id);
        value.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
        });
    };

    return (
        <div className="w-full bg-white xs:hidden">
            <div className="container xs:p-4 sm:p-4 lg:p-4 xl:p-4 mx-auto py-4">
                <div className="flex xs:flex-col sm:flex-col md:flex-row xs:gap-10 sm:gap-10 md:gap-5 lg:gap-0 md:w-full lg:w-[95%] xl:[80%] font-medium">
                    {title.map((data, i) => (
                        <div
                            key={i}
                            className="flex-1 border-l-2 border-[#D42234] md:p-4 lg:p-6 flex justify-center items-center text-center hover:cursor-pointer hover:underline"
                            onClick={() => ScrollToPage(data.id)}
                        >
                            <p className="md:w-[70%] xl:w-[100%] text-xl" dangerouslySetInnerHTML={{ __html: data?.value }}></p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
