import makeRequest from './index';

function generateParams(params = {}, featured = false, limit = 5) {
    let aritcle = '';
    if (params && params.length !== 0) {
        aritcle = params
            .map((key) => `&article_type[]=${key.value}`)
            .join('');
    }
    if (featured) {
        aritcle = `${aritcle}&featured=${featured}`;
    }
    if (limit) {
        aritcle = `${aritcle}&limit=${limit}`;
    }
    return `${aritcle}`;
}

export function fetchArticleByType(articleType, type, limit, date = null, order = '') {
    let url = `/v1/article_types/articles?${generateParams(articleType, type, limit)}&order=${order
        }`;
    if (date) {
        url = `/v1/article_types/articles?${generateParams(articleType, type, limit)}&date=${date}&order=${order
            }`;
    }
    return makeRequest({
        uri: url,
    });
}

export function fecthArticle(articleType, type) {
    let url = `/v1/article_types/articles?article_type[]=${articleType}`;
    if (type) {
        url = `${url}&featured=${type}`;
    }
    return makeRequest({
        uri: url,
        method: 'GET',
    });
}

export function getArticleData(alias_name) {
    return makeRequest({
        uri: `/v1/articles/${alias_name}`,
        method: 'GET',
    });
}

export function getCelebrators() {
    return makeRequest({
        uri: '/v1/users/celebrators',
        method: 'GET',
    });
}

export function getRecentData() {
    return makeRequest({
        uri: '/v1/articles/recent_events',
        method: 'GET',
    });
}
