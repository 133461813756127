import React, { useEffect, useState } from 'react';
import { DummyImage } from 'config';
import { fetchArticleByType } from 'api/article';
import { ButtonStyle } from 'components/button';
import Carousel from 'react-multi-carousel';
import { deleteAllCookie } from 'helpers';

export const Banner = () => {
    const [data, setData] = useState([]);
    const [errorPage, setErrorPage] = useState(true);
    const requestParams = [
        { name: 'celebrating_banner', value: 'celebrating_banner' },
    ];

    useEffect(() => {
        setTimeout(() => {
            fetchArticleByType(requestParams, false, 5, null, 'position').then(({ data }) => {
                setData(data);
                setErrorPage(false);
            }).catch((error) => {
                if (error[0] === 401) {
                    deleteAllCookie();
                }
                setErrorPage(true);
            });
        }, 2000);
    }, []);
    if (errorPage) {
        <div></div>;
    }
    const breakpoint = {
        desktop: {
            breakpoint: { max: 4000, min: 0 },
            items: 1
        }
    };
    return (
        // <>
        //     {data?.[0]?.id &&
        //         <div className="w-full bg-[#FDF4F5] xs:py-10 sm:py-16 md:py-20 text-black">
        //             <div className="sm:container mx-auto xs:px-4 sm:px-4 md:px-4 lg:px-4 flex xs:flex-col sm:flex-col md:flex-row-reverse sm:p-4 md:p-4 xs:gap-5 sm:gap-5 md:gap-5 lg:gap-10 xl:gap-0">
        //                 <div
        //                     className="xs:h-[300px] sm:h-[300px] md:h-[300px] lg:h-[400px] md:flex-[0.6_1_0%] w-full bg-no-repeat bg-center bg-cover xl:flex-[0.8_1_0%]"
        //                     style={{ backgroundImage: `url(${data[0]?.title_image?.url || DummyImage})` }}
        //                 ></div>
        //                 <div className="flex-1 flex flex-col xs:gap-6 sm:gap-10">
        //                     <div className="">
        //                         <h2
        //                             className=""
        //                         >{data[0]?.title}</h2>
        //                     </div>
        //                     <div><p className='text-justify'>{data[0]?.short_description}</p></div>
        //                 </div>
        //             </div>
        //         </div>
        //     }
        // </>
        <>
            {data[0]?.id &&
                <Carousel
                    responsive={breakpoint}
                    showDots={false}
                    className="xs:bg-white"
                    dotListClass="banner_dot_list"
                    infinite={false}
                    autoPlay={false}
                    autoPlaySpeed={10000}
                    arrows={false}
                >
                    {data.map((value, i) => {
                        const image = value?.banner_image?.url || DummyImage;
                        return (
                            <>
                                <div key={i} className="sm:h-[650px] md:h-[700px] lg:h-[750px] xs:px-4 bg-white flex xs:flex-col sm:flex-row xs:gap-3 sm:gap-4 md:gap-8 lg:gap-12 2xl:gap-20">
                                    <div className="xs:h-[400px] sm:h-auto sm:flex-1 md:flex-[1.4_1_0%] lg:flex-[1.6_1_0%] bg-no-repeat xs:bg-cover sm:bg-cover bg-center" style={{ backgroundImage: `url(${image})` }}>
                                    </div>
                                    <div className="flex-1 xl:pr-6 ">
                                        <div className="relative xs:text-[1.4rem] sm:text-[1.8rem] md:text-[1.7rem] lg:top-0 font-medium xl:top-0 xs:right-[0%] sm:right-[36%] xs:pl-5 sm:py-2 sm:pl-5 md:py-10 md:pl-10 lg:pl-16 lg:py-[5rem] xl:pl-20 xl:py-[7rem] bg-white">

                                            <h1>{value.title}</h1>
                                        </div>
                                        <div className="xs:p-5 sm:pr-0 md:pr-0 xs:w-full sm:w-[80%]">
                                            <div>
                                                <p className='text-justify leading-9'>{value.short_description}</p>
                                            </div>
                                            <ButtonStyle title="read on" style="mb-[50px] mt-14" url={`/articles/${value?.alias_name}`} />
                                        </div>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </Carousel>
            }
        </>
    );
};
