import makeRequest from '.';

export const login = (requestBody) => {
    return makeRequest({
        method: 'PUT',
        body: JSON.stringify(requestBody),
        uri: '/authentication/login'
    });
};

export const sendOTP = (requestBody) => {
    return makeRequest({
        method: 'PUT',
        body: JSON.stringify(requestBody),
        uri: '/authentication/send_otp'
    });
};

export const verifyOTP = (requestBody) => {
    return makeRequest({
        method: 'PUT',
        body: JSON.stringify(requestBody),
        uri: '/authentication/verify_otp'
    });
};

export const setPassword = (requestBody) => {
    return makeRequest({
        method: 'PUT',
        body: JSON.stringify(requestBody),
        uri: '/authentication/set_password'
    });
};

export const logout = () => {
    return makeRequest({
        method: 'PUT',
        uri: '/authentication/logout'
    });
};
