import React, { useEffect, useState } from 'react';
import { getCelebrators } from 'api/article';
import { FarewelImages, BirthdayImages } from 'helpers';
import moment from 'moment';

export const Birthday = () => {
  const [data, setData] = useState([]);
  const farewellImages = FarewelImages();
  const birthdayImages = BirthdayImages();

  useEffect(() => {
    getCelebrators().then(({ data }) => {
      setData(data);
    }).catch(error => {
      console.log(error, 'error');
    });
  }, []);

  return (
    <>
      {
        <div className="w-full bg-[#231F1F]" id="birthday">
          <div className="sm:container xs:px-4 sm:px-4 md:px-4 lg:px-4 mx-auto">
            <div className="flex justify-between xs:flex-col sm:flex-row text-white sm:container xs:gap-4 mx-auto z-[99999] py-[3rem]">
              <div className="xs:w-full sm:w-2/4 md:w-1/3 font-medium">
                <p className="sm:text-[1rem] md:text-xl lg:text-[1.3rem] uppercase">
                  Birthdays, Anniversaries & farewells
                </p>
              </div>
            </div>
            <div className="flex flex-row flex-wrap gap-16 pb-16 ">
              {data.length <= 0 &&
                <div className='flex justify-between text-[#ffff] text-2xl justify-center'>
                  NO BIRTHDAYS AND ANNIVERSARIES IN THIS WEEK
                </div>}
              {data?.map((value, i) => {
                const randomImage = value.celebration === 'birthday' ? birthdayImages[Math.floor(Math.random() * birthdayImages.length)] : farewellImages[Math.floor(Math.random() * farewellImages.length)];
                return <div key={i} className="flex flex-col gap-10 hover:cursor-pointer sm:w-[25%] text-white xs:flex-[0_0_100%] sm:flex-[0_0_44%] md:flex-[0_0_27%] lg:flex-[0_0_20%] xl:flex-[0_0_15.7%] 2xl:[0_0_16.5%] birth_day">
                  <div className="xs:h-[300px] sm:h-[200px]  overflow-hidden">
                    <img
                      className="w-full h-full object-fit img_class"
                      src={randomImage}
                      alt="... "
                    />
                  </div>
                  <div className="flex flex-col gap-3">
                    <div className="flex-1 flex justify-between">
                      <div>
                        <p className="capitalize">{value.celebration}</p>
                      </div>
                      <div className="">
                        {moment(value.date).format('DD-MMM')}
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="flex-1">
                        <h4 className="text-[1.2rem]">{value.name}</h4>
                      </div>
                    </div>
                  </div>
                </div>;
              })}
            </div>
          </div>
        </div>
      }
    </>
  );
};
