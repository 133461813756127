import moment from 'moment';

const jsonHeaders = {
    Accept: 'application/json',
    'Content-type': 'application/json'
};

const token = getCookie('auth_token');

const formHeader = {
    Authorization: `API_KEY ${token}`,
};

function getCookie(name) {
    const cookie = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return cookie && cookie[2];
}

function setCookie(name, value, days = 0, hours = 0) {
    const date = new Date();
    if (days || hours) {
        date.setTime(
            date.getTime() + hours * 60 * 60 * 1000 + 24 * 60 * 60 * 1000 * days
        );
        document.cookie =
            name + '=' + value + ';path=/;expires=' + date.toGMTString();
    } else {
        document.cookie = name + '=' + value + ';path=/';
    }
}

function headers() {
    const token = getCookie('auth_token');

    return token
        ? {
            ...jsonHeaders,
            Authorization: `API_KEY ${token}`,
        }
        : { ...jsonHeaders };
}

function path(value) {
    const path_name = value.slice(10);
    return path_name;
}

function titleize(str) {
    if (!str) {
        return str;
    }

    return str
        .split(' ')
        .map(function (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        })
        .join(' ')
        .split('_')
        .map(function (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        })
        .join(' ');
}

function DateFormat(str, a) {
    const convertDate = new Date(str);
    const date = convertDate.getDate();
    const year = convertDate.getFullYear();
    let month = '';
    if (a) {
        month = convertDate.toLocaleString('default', { month: 'short' });
    } else {
        month = convertDate.toLocaleString('default', { month: 'long' });
    }
    const format = date + ' ' + month + ' ' + year;
    return format;
}

function FirstLetter(str) {
    return str?.charAt(0);
}

function deleteAllCookie() {
    document.cookie.split(';').forEach(function (c) {
        document.cookie = c
            .replace(/^ +/, '')
            .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
    window.location.pathname = '/login';
}

function BirthdayImages() {
    return [
        'https://docs-assets.katomaran.tech/images/leaderboard/birthday/2022/02/Mask_Group.png',
        'https://docs-assets.katomaran.tech/images/leaderboard/birthday/2022/02/Mask_Group-1.png',
        'https://docs-assets.katomaran.tech/images/leaderboard/birthday/2022/02/Mask_Group-2.png',
        'https://docs-assets.katomaran.tech/images/leaderboard/birthday/2022/02/Mask_Group-3.png',
        'https://docs-assets.katomaran.tech/images/leaderboard/birthday/2022/02/Mask_Group-4.png',
        'https://docs-assets.katomaran.tech/images/leaderboard/birthday/2022/02/Mask_Group-5.png',
        'https://docs-assets.katomaran.tech/images/leaderboard/birthday/2022/02/Mask_Group-6.png',
        'https://docs-assets.katomaran.tech/images/leaderboard/birthday/2022/02/Mask_Group-7.png'
    ];
}

function FarewelImages() {
    return [
        'https://docs-assets.katomaran.tech/images/farewell/2022/02/farewell1.png',
        'https://docs-assets.katomaran.tech/images/farewell/2022/02/farewell2.png',
        'https://docs-assets.katomaran.tech/images/farewell/2022/02/farewell3.png',
        'https://docs-assets.katomaran.tech/images/farewell/2022/02/farewell5.png',
        'https://docs-assets.katomaran.tech/images/leaderboard/celebrating_people/2022/02/Mask_Group.png'
    ];
}

function dayDifference(end_date) {
    const startDate = moment();
    const endDate = moment(end_date);
    return parseInt(startDate.diff(endDate, 'days'));
}

function articleNew(published_date) {
    const differnce_count = dayDifference(published_date);
    return differnce_count >= 0 && differnce_count < 3;
}

export {
    jsonHeaders,
    getCookie,
    setCookie,
    headers,
    path,
    titleize,
    DateFormat,
    FirstLetter,
    deleteAllCookie,
    BirthdayImages,
    FarewelImages,
    dayDifference,
    articleNew,
    formHeader
};
