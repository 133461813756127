import React from 'react';
import 'assets/css/common.css';

export const Loader = () => {
    return (
        <div className="flex justify-center items-center w-full h-screen bg-white sticky bg-white top-0 z-[99999999999]">
            <div className="sk-chase">
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
            </div>
        </div>
    );
};
