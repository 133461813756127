import makeRequest from './index';

export function fetchUserData() {
    return makeRequest({
        uri: '/v1/users/profile',
        method: 'GET'
    });
}

export function updateProfile(body) {
    return makeRequest({
        uri: '/v1/users/profile',
        method: 'PUT',
        body: body
    });
}
